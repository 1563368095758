.roadmap__mid {
  display: flex;
  justify-content: center;
  height: 200px;
}

.roadmap__text {
  text-align: center;
  margin: 70px 0px 30px 0px;
  font-size: 35px;
}

.hidden, .hidden > p {
  color: #65c4e1 !important;
}

.roadmap__right {
  padding-left: 60px;
  margin-top: 57px;
}

.roadmap__left {
  padding-right: 60px;
  margin-top: 57px;
  text-align: end;
}

.roadmap__right > h1 {
  font-weight: 900;
  font-size: 32px;
  margin-top: 12px;
}

.roadmap__right > img {
  margin-top: 10px;
  width: 73%;
}

.roadmap__right > h1 {
  font-weight: 900;
  font-size: 32px;
  margin-top: 12px;
}

.roadmap__left > h1 {
  font-weight: 900;
  font-size: 32px;
  margin-top: 12px;
}

.mid__line {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  width: 8%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  background-color: #ffffff8f;
  position: relative;
}

.mid__line1 {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  width: 8%;
  background-color: #ffffff8f;
  position: relative;
}

.mid__circle {
  position: absolute;
  width: 60px;
  height: 60px;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid white;
  background-color: #9ca5ac;
  top: 50px;
  font-weight: 900;
}

.mid__circle1 {
  position: absolute;
  width: 60px;
  height: 60px;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  top: 50px;
  font-weight: 900;
}

.roadmap__mid2 {
  display: flex;
  justify-content: center;
}

.roadmap__left > img {
  margin-top: 30px;
  width: 73%;
}

.border__radbot {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
