.content__text {
  font-size: 18px;
  font-weight: 300;
}

.outer__div78 {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.outer__div78 > .img__1 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid white;
}

.outer__div78 > .img__2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid white;
}

.outer__div78 > .img__3 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid white;
}

.btn__1 {
  background-color: white;
  border: none;
  border-radius: 7px;
  color: #50a1b9;
  padding: 10px 25px;
  font-weight: 800;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.226);
}

.btn__1 > i {
  color: #50a1b9;
  font-size: 19px;
  margin-right: 8px;
  margin-top: 1px;
}

.btn__2 {
  background-color: transparent;
  border: none;
  border-radius: 7px;
  color: white;
  width: 230px;
  padding: 10px 0;
  font-weight: 800;
  border: 1px solid white;
}

.btn__2 > i {
  font-size: 18px;
  margin-right: 5px;
  margin-top: 3px;
}
