body {
  height: 100vh;
  background-color: #65c4e1;
  width: 100%;
  color: white;
  font-family: "Archivo", sans-serif;
}

.connect__btn {
  background-color: white;
  border: none;
  color: black;
  border-radius: 7px;
  padding: 6px 16px;
  margin-left: 14px;
  font-size: 16px;
  font-weight: 700;
}

#price {
  color: black !important;
}

.mint_btn {
  background-color: white;
  border: none;
  color: black;
  border-radius: 7px;
  padding: 9px 35px;
  margin-left: 14px;
  font-size: 16px;
  font-weight: 800;
  margin: auto;
  display: flex;
  margin-top: -100px;
  position: relative;

  justify-content: center;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

* {
  --primary: #65c4e1;
  --secondary: #ff72c0;
  --light: #ffff;
  --dark: #010101;
  color: var(--light);
  --bg-graidient: linear-gradient(
    to bottom right,
    var(--primary),
    var(--secondary)
  );
  --border-gradient: linear-gradient(
    to bottom right,
    var(--primary),
    var(--secondary)
  );
}

section {
  margin: 150px 0px;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* //whitlist area */

.time {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.time > h1 {
  font-weight: 900;
}

.time__whitlist > div {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.226);
}

.time__whitlist > div > h3 {
  color: #50a1b9;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 30px;
}

@media (max-width: 768px) {
  section {
    margin: 70px 0px;
  }

  .banner > img {
    height: 400px;
    object-fit: cover;
    object-position: 34% 20%;
  }
  .time > h1 {
    font-size: 35px;
    text-align: center;
    padding: 0px 20px;
  }
  h1 {
    text-align: center;
    font-size: 35px !important;
  }
  .mobile__screen {
    width: 80%;
    margin: 50px auto;
  }
  .roadmap__right {
    padding-left: 0px !important;
  }
  .roadmap__left {
    padding-right: 0px !important;
  }
  .roadmap__mid {
    display: flex;
    justify-content: center;
    height: auto !important;
  }
  .mid__line {
    width: 18% !important;
  }
  .mid__line1 {
    width: 18% !important;
  }

  .roadmap__left > img {
    margin-top: 30px;
    width: 100% !important;
  }
  .roadmap__right > img {
    margin-top: 10px;
    width: 100% !important;
  }

  .outer__div78 {
    margin: 40px auto !important;
    width: 95% !important;
  }

  .nft__team {
    display: grid !important;
    grid-template-columns: 1fr !important;
    column-gap: 20px !important;
    row-gap: 20px !important;
  }
}
